import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ProductsOne from '../components/Products/ProductsOne'
import StartProject from '../components/Common/StartProject'

const Services = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Products" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Products" 
            />
            <ProductsOne />
            <StartProject />
            <Footer />
        </Layout>
    );
}

export default Services